<template>
  <b-container class="container-content">
    <!-- <div class="page-header"> -->
    <h4 class="page-header-text mb-4">{{ $t("nav.payment_history") }}</h4>
    <!-- </div> -->
    <b-tabs content-class="mt-3" v-model="tabValue">
      <b-tab :title="$t('tab_title.purchase')">
        <div class="purchase-history-table">
          <b-table :items="paymentItems" :fields="fields">
            <template v-slot:table-busy>
              <div class="text-center text-dark">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template v-slot:cell(price)="data">
              <span style="white-space: nowrap;">{{ data.value }}</span>
            </template>
            <template v-slot:cell(description)="data">
              <!-- <span v-html="data.item.descriptionHTML"></span> -->
              {{ data.value }}
            </template>
            <template v-slot:cell(maid)="data">
              <div v-if="data.value">
                <b-img
                  :src="data.value.image_url"
                  class="mr-3"
                  style="width: 32px; height: 32px; border-radius: 32px;"
                />
                <span
                  >{{ data.value.nick_name ? `"${data.value.nick_name}" ` : ``
                  }}{{
                    `${data.value.first_name} ${data.value.last_name}`
                  }}</span
                >
              </div>
              <div v-else>
                {{ $t("form_label.waiting_for_maids_confirmation") }}
              </div>
            </template>
            <template v-slot:cell(invoice)="data">
              <b-button
                variant="primary"
                @click="onViewInvoice(data.value)"
                size="sm"
                >{{ $t("button.view_receipt") }}</b-button
              >
            </template>
          </b-table>
          <div
            class="purchase-history-mobile"
            v-for="(payment, index) in paymentItems"
            :key="index"
          >
            <div
              class="purchase-history-mobile-item d-flex align-items-start mb-2"
            >
              <b-img :src="payment.icon" />
              <div class="purchase-history-detail">
                <span
                  >{{ $t("payment_history.purchase_date") }} :
                  {{ payment.purchase_date }}</span
                ><br />
                <span>{{ payment.description }}</span
                ><br />
                <span
                  >{{ $t("payment_history.starting_date") }} :
                  {{ payment.start_at }}</span
                ><br />
                <span v-if="payment.maid"
                  >{{
                    payment.maid.nick_name
                      ? `"${payment.maid.nick_name}" `
                      : ``
                  }}{{
                    `${payment.maid.first_name} ${payment.maid.last_name}`
                  }}</span
                >
                <div v-else>
                  {{ $t("form_label.waiting_for_maids_confirmation") }}
                </div>
              </div>
              <div
                class="purchase-history-detail2 d-flex flex-column align-items-end"
              >
                <span class="price">{{ payment.price }}</span>
              </div>
            </div>
            <div class="row w-100 m-0">
              <div class="col-6 offset-3">
                <b-button
                  variant="primary"
                  size="sm"
                  block
                  @click="onViewInvoice(payment.invoice)"
                  >{{ $t("button.view_receipt") }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="paymentItems.length !== 0">
          <b-button
            block
            variant="link"
            v-if="!isLastPageOfPayment"
            @click="getPaymentHistory"
            >{{ $t("button.load_more") }}</b-button
          >
        </div>
      </b-tab>
      <b-tab :title="$t('tab_title.refund')">
        <div class="purchase-history-table" v-if="paymentItems.length !== 0">
          <b-table :items="refundItems" :fields="refundFields">
            <template v-slot:table-busy>
              <div class="text-center text-dark">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template v-slot:cell(price)="data">
              <span style="white-space: nowrap;">{{ data.value }}</span>
            </template>
            <template v-slot:cell(descriptionHTML)="data">
              <span v-html="data.value"></span>
            </template>
            <template v-slot:cell(maid)="data">
              <div v-if="data.value">
                <b-img
                  :src="data.value.image_url"
                  class="mr-3"
                  style="width: 32px; height: 32px; border-radius: 32px;"
                />
                <span
                  >{{ data.value.nick_name ? `"${data.value.nick_name}" ` : ``
                  }}{{
                    `${data.value.first_name} ${data.value.last_name}`
                  }}</span
                >
              </div>
              <div v-else>{{ $t("form_label.no_maid_confirmed") }}</div>
            </template>
            <template v-slot:cell(invoice)="data">
              <b-button
                variant="primary"
                @click="onViewInvoice(data.value)"
                size="sm"
                v-if="data.value.refund"
                >{{ $t("button.view_receipt") }}</b-button
              >
            </template>
          </b-table>
          <div
            class="purchase-history-mobile"
            v-for="(payment, index) in refundItems"
            :key="index"
          >
            <div class="purchase-history-mobile-item d-flex align-items-start">
              <b-img :src="payment.icon" />
              <div class="purchase-history-detail">
                <span
                  >{{ $t("payment_history.cancelled_date") }} :
                  {{ payment.purchase_date }}</span
                ><br />
                <span>{{ payment.description }}</span
                ><br />
                <span
                  >{{ $t("payment_history.starting_date") }} :
                  {{ payment.start_at }}</span
                ><br />
                <span v-if="payment.maid"
                  >{{
                    payment.maid.nick_name
                      ? `"${payment.maid.nick_name}" `
                      : ``
                  }}{{
                    `${payment.maid.first_name} ${payment.maid.last_name}`
                  }}</span
                >
                <div v-else>{{ $t("form_label.no_maid_confirmed") }}</div>
              </div>
              <div
                class="purchase-history-detail2 d-flex flex-column align-items-end"
              >
                <span class="price">{{ payment.price }}</span>
              </div>
            </div>
            <div class="row w-100 m-0 mt-2" v-if="payment.isRefunded">
              <div class="col-6 offset-3">
                <b-button
                  variant="primary"
                  size="sm"
                  block
                  @click="onViewInvoice(payment.invoice)"
                  >{{ $t("button.view_receipt") }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center py-4">
          <p v-html="$t('form_label.there_are_no_refunds_to_show')"></p>
        </div>
        <div v-if="paymentItems.length !== 0">
          <b-button
            block
            variant="link"
            v-if="!isLastPageOfRefund"
            @click="getRefundHistory"
            >{{ $t("button.load_more") }}</b-button
          >
        </div>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { createNamespacedHelpers } from "vuex";
import ApiClient from "../../services";
import moment from "moment";
import { exportPDFFromReceipt } from "../../utils";
import { STATUS } from "../../config/booking";
import { getTotalPrice,getAllTotalPrice } from "../../helpers/receipt";
import momentTZ from "moment-timezone";

const UserStore = createNamespacedHelpers("user");

@Component({
  computed: {
    ...UserStore.mapState([
      "first_name",
      "last_name",
      "is_verified_phone",
      "phone",
    ]),
  },
})
export default class PaymentHistory extends Vue {
  isLoading = false;
  isLoadingRefund = false;
  isLastPageOfPayment = false;
  totalPages = 0;
  currentPage = 0;

  isLastPageOfRefund = false;
  totalRefundPages = 0;
  currentRefundPage = 0;

  // Tabs
  defaultTab = "purchase";
  tabValue = 0;

  payments = [];
  refunds = [];

  get fields() {
    return [
      {
        key: "purchase_date",
        label: this.$t("payment_history.purchase_date"),
      },
      {
        key: "description",
        label: this.$t("payment_history.description"),
        tdClass: "td-description",
      },
      {
        key: "start_at",
        label: this.$t("payment_history.starting_date"),
      },
      {
        key: "maid",
        label: this.$t("payment_history.maid"),
      },
      {
        key: "price",
        label: this.$t("payment_history.price"),
        class: "tdPrice",
      },
      {
        key: "invoice",
        label: "",
      },
    ];
  }
  async getPriceMultiPackage(obj) {
    console.log("obj,obj", obj);
    //  alert("ok")
    // if (!isEmpty(this.bookingForm.package)) {
    try {
      const result = await ApiClient.getBookingPrice({
        duration: obj.booking.duration,
        multi_times: obj.booking.multi_times,
        type: "MULTI_PACKAGE",
        bring_equipment: obj.booking.bring_equipment,
      });
      return result.data.total_price;
      // this.totalPrice = get(result, 'data.total_price')
      // console.log("data total proce",this.totalPrice);
    } catch (e) {
      console.log(e.response);
    }
    // }
  }

  get refundFields() {
    return [
      {
        key: "purchase_date",
        label: this.$t("payment_history.cancelled_date"),
      },
      {
        key: "descriptionHTML",
        label: this.$t("payment_history.description"),
        tdClass: "td-description",
      },
      {
        key: "start_at",
        label: this.$t("payment_history.starting_date"),
      },
      {
        key: "maid",
        label: this.$t("payment_history.maid"),
      },
      {
        key: "price",
        label: this.$t("payment_history.price"),
        class: "tdPrice",
      },
      {
        key: "invoice",
        label: "",
      },
    ];
  }

  async mounted() {
    this.$store.commit('setIsLoading', true)
    await Promise.all([this.getPaymentHistory(), this.getRefundHistory()]);
    this.initTab();
    this.$store.commit('setIsLoading', false)
  }

  async getPaymentHistory() {
    try {
      this.isLoading = true;
      const result = await ApiClient.paymentHistory({
        page: this.currentPage + 1,
        limit: 20,
      });
      const { page_count, payment } = result.data;

      this.payments = [...this.payments, ...payment];
      this.currentPage += 1;
      this.isLastPageOfPayment =
        this.currentPage === page_count || page_count === 0;
    } catch (e) {
      console.log(e.response);
    }
    this.isLoading = false;
  }

  initTab() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab") || "purchase";
    this.defaultTab = tab;
    this.tabValue = ["purchase", "refund"].indexOf(tab);
  }

  @Watch("tabValue")
  onTabChanged(index) {
    this.defaultTab = ["purchase", "refund"][index];
    this.tabValue = index;
    window.history.replaceState(null, null, `?tab=${this.defaultTab}`);
  }

  async getRefundHistory() {
    try {
      this.isLoadingRefund = true;
      const result = await ApiClient.refundHistory({
        page: this.currentRefundPage + 1,
        limit: 20,
      });
      const { page_count, refund } = result.data;

      this.refunds = [...this.refunds, ...refund];
      this.currentRefundPage += 1;
      this.isLastPageOfRefund =
        this.currentRefundPage === page_count || page_count === 0;
    } catch (e) {
      console.log(e.response);
    }
    this.isLoadingRefund = false;
  }

  get paymentItems() {
    const refundPayment = [];
    const historyPayment = [];
    this.payments.forEach((payment) => {
      const isCancelledByCustomer = payment.booking.booking_calendars.some(
        ({ status }) => status === STATUS.canceledByCustomer
      );

      let icon, description;
      const duration = payment.payment_booking_snapshot
        ? payment.payment_booking_snapshot.duration
        : 0;
      if (payment.product_detail.includes("ONE_TIME")) {
        description = `${this.$t(
          "payment_history.one_time"
        )}: ${duration} ${this.$t("alias.hrs")}`;
        icon = require("../../assets/images/icons/one-time-green.svg");
      } else {
        description = `${this.$t(
          "payment_history.multi_package"
        )}: ${duration} ${this.$t("alias.hrs")} x ${
          payment.booking.multi_times
        } ${this.$t("alias.times")}`;
        icon = require("../../assets/images/icons/multi-package-yellow.svg");
      }

      const price = `฿ ${parseFloat(getTotalPrice(payment))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
      const [booking] = payment.payment_booking_snapshot
        ? payment.payment_booking_snapshot.payment_booking_calendar_snapshots
        : [];

      const dateFormat =
        this.$i18n.locale === "th" ? `DD MMM YYYY, H:mm` : `MMM DD, YYYY, H:mm`;
      const start_at = booking
        ? moment(booking.start_datetime)
            .locale(this.$i18n.locale)
            .format(dateFormat)
        : ``;

      const result = {
        create_datetime: payment.create_datetime,
        purchase_date: moment(payment.create_datetime)
          .locale(this.$i18n.locale)
          .format(dateFormat),
        description,
        descriptionHTML: description,
        start_at,
        maid: payment.booking.maid ? payment.booking.maid.user : null,
        price,
        invoice: { ...payment, isCancelled: false },
        icon,
      };
      historyPayment.push(result);
      if (isCancelledByCustomer) {
        const cloneObj = { ...result };
        const updateDateTime =
          payment.booking.booking_calendars[0].update_datetime;
        cloneObj.price = `฿ -${parseFloat(
          getAllTotalPrice(payment) * 0.95
        ).toFixed(2)}`;
        cloneObj.description = `== ${this.$t(
          "payment_history.refunded"
        )} == ${description}`;
        cloneObj.descriptionHTML = `== ${this.$t(
          "payment_history.refunded"
        )} == ${description}<br>${this.$t("payment_history.purchase_date")}: ${
          cloneObj.purchase_date
        }`;
        cloneObj.purchase_date = moment(updateDateTime)
          .locale(this.$i18n.locale)
          .format(dateFormat);
        refundPayment.push(cloneObj);
        cloneObj.create_datetime = updateDateTime;
        cloneObj.invoice = { ...cloneObj.invoice, isCancelled: true };
      }
    });
    return historyPayment;
  }

  get refundItems() {
    return this.refunds.map((payment) => {
      let icon, description;
      const duration = payment.duration;
      if (payment.type.includes("ONE_TIME")) {
        description = `${this.$t(
          "payment_history.one_time"
        )}: ${duration} ${this.$t("alias.hrs")}`;
        icon = require("../../assets/images/icons/one-time-green.svg");
      } else {
        description = `${this.$t(
          "payment_history.multi_package"
        )}: ${duration} ${this.$t("alias.hrs")} x ${
          payment.multi_times
        } ${this.$t("alias.times")}`;
        icon = require("../../assets/images/icons/multi-package-yellow.svg");
      }

      const dateFormat =
        this.$i18n.locale === "th" ? `DD MMM YYYY, H:mm` : `MMM DD, YYYY, H:mm`;
      const start_at = moment(payment.booking_calendars[0].start_datetime)
        .locale(this.$i18n.locale)
        .format(dateFormat);

      const isRefunded = payment.refund !== null;
      const price = isRefunded
        ? `${this.$t("payment_history.refunded")}`
        : payment.booking_status === "Cancelled 5%"
        ? `฿ -${parseFloat(payment.total_price * 0.95)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
        : payment.booking_status === "Cancelled 50%"
        ? `฿ -${parseFloat(payment.total_price * 0.5)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
        : console.log("payment", payment);
      let descriptionHTML;
      if (isRefunded) {
        descriptionHTML =
          this.$i18n.locale === "en"
            ? `== ${this.$t(
                "payment_history.refund_processed"
              )} ==<br> ${description}`
            : `== ${this.$t(
                "payment_history.refund_processed"
              )} == ${description}`;
        description = `== ${this.$t(
          "payment_history.refund_processed"
        )} == ${description}`;
      } else {
        descriptionHTML =
          this.$i18n.locale === "en"
            ? `== ${this.$t(
                "payment_history.waiting_to_refund"
              )} ==<br> ${description}`
            : `== ${this.$t(
                "payment_history.waiting_to_refund"
              )} == ${description}`;
        description = `== ${this.$t(
          "payment_history.waiting_to_refund"
        )} == ${description}`;
      }

      return {
        create_datetime: payment.create_datetime || "No create date time",
        purchase_date: moment(payment.booking_calendars[0].update_datetime) // Cancelled date
          .locale(this.$i18n.locale)
          .format(dateFormat),
        description,
        descriptionHTML,
        start_at,
        maid: payment.maid ? payment.maid.user : null,
        price,
        invoice: { ...payment, isCancelled: isRefunded },
        icon,
        payment_booking_snapshot: payment.booking,
        isRefunded,
      };
    });
  }

  async onViewInvoice(paymentObj) {
    const labelTemplate = this.$t("receipt");
   let payment_method = null
    if (this.tabValue === 0) {
      let isMultipackage = paymentObj.booking.type === "MULTI_PACKAGE";
     
      let eq = 0;
      let date_check = new Date(2021, 10, 30);
      let isAfterLastUpdateVat =
        date_check < new Date(paymentObj.create_datetime);
      if (isMultipackage) {
        eq = await this.getPriceMultiPackage(paymentObj);
        paymentObj.total_multi_price = eq;
      }
      let price_equipment =
        eq > 0
          ? eq
          : paymentObj.booking.price + paymentObj.booking.equipment_price;
      let discountAndVat = parseFloat(parseFloat(price_equipment * 0.93 * 1.07).toFixed(2))
      let discountNewAccAndVat = parseFloat(parseFloat(price_equipment * 0.9 * 1.07).toFixed(2))
      let isDiscountAndVat = discountAndVat === paymentObj.booking.total_price
      let isDiscountNewAccAndVat = discountNewAccAndVat === paymentObj.booking.total_price
      if (isAfterLastUpdateVat) {
        if (isDiscountAndVat) {
          paymentObj.vat = price_equipment * 0.93 * 0.07
          paymentObj.discount = price_equipment * 0.07
        }
        if (isDiscountNewAccAndVat) {
          paymentObj.vat = price_equipment * 0.9 * 0.07
          paymentObj.discount = price_equipment * 0.1
        } else {
          paymentObj.vat = price_equipment * 0.07;
          paymentObj.discount = 0;
        }
      } else {
        paymentObj.vat = 0;
        paymentObj.discount =
          price_equipment * 0.93 === paymentObj.total_price
            ? paymentObj.total_price
            : 0;
      }

    if(paymentObj.booking.payment_status.includes("BANK_TRANSFER")){
      payment_method ="bank_transfer"
    }
    else{
     payment_method = "paysolution"
    }
    }


    console.log("vat",paymentObj);
    // console.log("paymentObj", paymentObj);

paymentObj.payment_method = payment_method

    // this.getPriceMultiPackage()

    // paymentObj.vat ===

    await exportPDFFromReceipt(paymentObj, labelTemplate);
  }
}
</script>

<style lang="scss" scoped>
.td-description {
  max-width: 300px;
}
</style>
